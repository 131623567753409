import React from 'react'

const NotFound = () => {
  return (
    <div>
      {/* <Header /> */}
      <p className='font-bold text-6xl text-center my-10 capitalize'>Page not found</p>
    </div>
  )
}

export default NotFound
