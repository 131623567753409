import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CreateProduct, FetchVendors, UpdateProduct } from "../services/api";
import { useStateContext } from "./context";
import FormInputs from "./FormInputs";
import * as XLSX from "xlsx";
import axios from "axios";

const FormPage = () => {
  const [vendors, setVendors] = useState([]);
  const [loading_, setLoading_] = useState(false);
  const { data } = useStateContext();
  // const [selectedVendor, setSelectedVendor] = useState({});
  // const [file, setFile] = useState('')
  const [result, setResult] = useState({});
  const [uplaodType, setUplaodType] = useState(data ? "single" : "");
  const [imageUrl, setImageUrl] = useState({});
  const [inputFile, setInputFile] = useState({
    vendor: "",
    productName: data?.productName || "",
    desc: data?.description || "",
    code: data?.productCode || "",
    discount: data?.discount || 0,
    category: data?.category || "",
    subcategory: data?.subCategory || "",
    price: data?.price || "",
  });
  const [selectedPayload, setSelectedPayload] = useState([]);
  // console.log("formData", formData);
  // const subcategory = [
  //   {
  //     id: 1,
  //     category: 'electronics',
  //     subcategory: ['smartphone', 'fridge', 'television', 'laptop']
  //   },
  //   {
  //     id: 2,
  //     category: 'furniture',
  //     subcategory: ['sofa', 'chair', 'table',]
  //   },
  //   {
  //     id: 3,
  //     category: 'home-appliance',
  //     subcategory: ['Iron', 'air condition', 'sound system']
  //   },
  // ]
  const [excelFile, setExcelFile] = useState(null);
  const [excelFile2, setExcelFile2] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);

  // submit
  const [excelData, setExcelData] = useState(null);
  // it will contain array of objects

  // handle File
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const handleFile = (e) => {
    let selectedFile = e.target.files[0];
    setExcelFile2(selectedFile);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    // const payload = {
    //   vendor: inputFile.vendor,
    //   file: excelFile2,
    // };
    console.log("payload", formData);
    if (selectedFile) {
      // console.log(selectedFile.type);
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);
          setExcelFile(e.target.result);
        };
      } else {
        toast.error("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      toast.error("plz select your file");
    }
  };

  // submit function
  const handleExcel = () => {
    // e.preventDefault();
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(data);
    } else {
      setExcelData(null);
    }
  };
  useEffect(() => {
    excelFile && handleExcel();
  }, [excelFile]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllVendors();
  }, []);

  const user = localStorage.getItem("user");
  const getAllVendors = async () => {
    // setLoading_(true)
    const response = await FetchVendors();
    if (response.status === 200) {
      if (response.data.responseCode) {
        setVendors(response.data.responseData);
        // const _response = response.data.responseData.map((res) => {
        //   return {
        //     ...res,
        //     name: res.vendorName,
        //     value: res.id,
        //   };
        // });
        setLoading_(false);
      }
    }
    setLoading_(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputFile({
      ...inputFile,
      [name]: value,
    });
  };
  const widget = (file) => {
    const fcloptions = {
      headers: {
        // Authorization: `Bearer ${data}`,
        "Access-Control-Allow-Origin": "*",
        "X-API-KEY": "FCL-LOAN-REQUEST",
      },
    };
    const url = "https://mobileapp.fastcredit-ng.com/api/Utility/uploads";
    // e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    // formData.append("api_key", process.env.REACT_APP_API_KEY);
    // formData.append("upload_preset", process.env.REACT_APP_UPLOAD);
    // formData.append("timestamp", new Date());
    // // formData.append("signature", signData.signature);
    // // formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
    // formData.append("folder", "fastcredit");

    fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        // Authorization: `Bearer ${data}`,
        "Access-Control-Allow-Origin": "*",
        "X-API-KEY": "FCL-LOAN-REQUEST",
      },
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        console.log("iii", JSON.parse(data));
        setImageUrl(JSON.parse(data));
      });
  };

  useEffect(() => {
    const resultup = [];
    const id = localStorage.getItem("user");
    // console.log("excelData", excelData);
    excelData?.length > 0 &&
      excelData?.forEach((employee) => {
        resultup.push({
          userId: user,
          productType: employee?.ProductCategory,
          productName: employee?.ProductCategory,
          description: employee?.ProductDescription,
          price: employee?.ProductPrice,
          productCode: employee?.ProductCode,
          category: employee?.ProductCategory,
          subCategory: employee?.ProductSubCategory,
          discount: employee?.ProductDiscount || 0,
          tags: [""],
          imageUrl: employee?.ProductImageUrl,
          qrCodeUrl: "",
          status: "",
          vendor: id,
        });
        // console.log("resultup", resultup);
        setSelectedPayload(resultup);
      });
  }, [excelData]);

  const handleFileInput = (e) => {
    // handle validations
    const file = e.target.files[0];
    if (!file) return;
    widget(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // const payload = {
    //   userId: user,
    //   productType: inputFile.category,
    //   productName: inputFile.category,
    //   description: inputFile.desc,
    //   price: inputFile.price,
    //   productCode: inputFile.code,
    //   category: inputFile.category,
    //   subCategory: inputFile.subcategory,
    //   discount: inputFile.discount,
    //   tags: [""],
    //   imageUrl: result.secure_url,
    //   qrCodeUrl: "",
    //   status: "",
    //   vendor: inputFile.vendor,
    // };
    // const payload2 = {
    //   userId: user,
    //   productType: excelData?.ProductCategory,
    //   productName: excelData?.ProductName,
    //   description: excelData?.ProductDescription,
    //   price: excelData?.ProductPrice,
    //   productCode: excelData?.ProductCode,
    //   category: excelData?.ProductCategory,
    //   subCategory: excelData?.ProductSubCategory,
    //   discount: excelData?.ProductDiscount || 0,
    //   tags: [""],
    //   imageUrl: excelData?.ProductImageUrl,
    //   qrCodeUrl: "",
    //   status: "",
    //   vendor: inputFile.vendor,
    // };

    setLoading_(true);
    // if (
    //   payload.category === "" ||
    //   payload.description === "" ||
    //   payload.imageUrl === undefined ||
    //   payload.productCode === "" ||
    //   payload.productName === "" ||
    //   payload.productType === "" ||
    //   payload.subCategory === "" ||
    //   payload.vendor === "" ||
    //   payload.price === ""
    // ) {
    //   setLoading_(false);
    //   toast.error("Please fill in all fields");
    // } else {
    const id = localStorage.getItem("user");
    data !== null
      ? UpdateProduct({
          productId: data.id,
          productType: inputFile.category,
          productName: inputFile.productName,
          description: inputFile.desc,
          price: inputFile.price,
          productCode: inputFile.code,
          category: inputFile.category,
          subCategory: inputFile.subcategory,
          discount: inputFile.discount,
          tags: [""],
          imageUrl: imageUrl?.responseData ?? data.imageUrl,
          vendor: id,
          userId: user,
        })
      : CreateProduct({
          productType: inputFile.category,
          productName: inputFile.productName,
          description: inputFile.desc,
          price: inputFile.price,
          productCode: inputFile.code,
          category: inputFile.category,
          subCategory: inputFile.subcategory,
          discount: inputFile.discount,
          tags: [""],
          imageUrl: imageUrl?.responseData,
          vendor: id,
          userId: user,
        })
          .then((res) => {
            toast.success(res.data.responseMessage);
            setLoading_(false);
          })
          .catch((err) => {
            console.log("err", err);
            setLoading_(false);
          });
    // }
    setInputFile({
      vendor: "",
      productName: "",
      desc: "",
      code: "",
      discount: "",
      category: "",
      subcategory: "",
      price: "",
    });
    setLoading_(false);
  };

  function handleSubmits(event) {
    event.preventDefault();
    const id = localStorage.getItem("user");
    event.preventDefault();

    const url = `https://mobileapp.fastcredit-ng.com/api/Product/UploadProducts`;

    const formData = new FormData();

    formData.append("file", excelFile2);
    console.log("excelFile2", excelFile2);
    formData.append("fileName", excelFile2.name);
    formData.append("vendor", id);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "X-API-KEY": "FCL-LOAN-REQUEST",
      },
    };

    axios.post(url, formData, config).then((response) => {
      console.log(response.data);
      toast.success(response.data.responseMessage);
    });
  }
  return (
    <>
      <div className="w-full lg:w-3/4 sm:px-40 py-6 mx-auto min-height my-2">
        <h3 className="text-center capitalize text-4xl text-gray-500 font-bold tracking-widest my-10">
          {" "}
          upload products
        </h3>

        <form>
          {/* <div className="flex items-center space-x-5">
            <p
              onClick={() => setUplaodType("single")}
              className="font-semibold hover:bg-slate-400 capitalize p-3 rounded w-max hover:text-white cursor-pointer bg-slate-100 m-3"
            >
              single upload
            </p>
            <p
              onClick={() => setUplaodType("excel")}
              className="font-semibold hover:bg-slate-400 capitalize p-3 rounded w-max hover:text-white cursor-pointer bg-slate-100 m-3"
            >
              upload via excel
            </p>
          </div>
          {uplaodType && ( */}
          <div className="flex items-center space-x-5">
            <p
              onClick={() => setUplaodType("single")}
              className={` font-semibold hover:bg-slate-400 capitalize p-3 rounded w-max hover:text-white cursor-pointer m-3 ${uplaodType ===
                "single" && "bg-slate-600 text-white"}`}
            >
              single upload
            </p>
            <p
              onClick={() => setUplaodType("excel")}
              className={`font-semibold hover:bg-slate-400 capitalize p-3 rounded w-max hover:text-white cursor-pointer m-3 ${
                uplaodType === "excel" ? "bg-slate-600 text-white" : ""
              }`}
            >
              upload via excel
            </p>
          </div>
          {/* <label className="block mt-2 ">
            <span className="block font-bold text-slate-700 text-1xl">
              Select Vendor Name
            </span>
            <select
              label="Select Vendor"
              onChange={handleInputChange}
              name="vendor"
              value={inputFile.vendor}
              className="w-1/2 px-3 py-2 mt-1 bg-white border rounded-md shadow-sm text-1xl boreder-slate-300 "
              onSelect={(item) => console.log(item)}
            >
              <option value="">select vendor</option>
              {vendors.map((vendor, i) => (
                <option value={vendor.id} key={i}>
                  {vendor.vendorName}
                </option>
              ))}
            </select>
          </label> */}

          {uplaodType === "excel" && (
            <>
              <label className="block mt-2 ">
                <h5>Upload Excel file</h5>
              </label>
              <input
                type="file"
                className="w-1/2 px-3 py-2 mt-1 bg-white border rounded-md shadow-sm text-1xl boreder-slate-300 "
                onChange={handleFile}
                required
              />
            </>
          )}
          {uplaodType === "single" && (
            <>
              <FormInputs
                inputFile={inputFile}
                handleInputChange={handleInputChange}
                handleFileInput={handleFileInput}
              />
              <button
                onClick={handleSubmit}
                className="bg-[#313A47] py-1 px-3 text-white w-max ml-4 mt-5"
              >
                {loading_ ? "loading..." : "SEND"}
              </button>
            </>
          )}
          {uplaodType === "excel" && (
            <button
              onClick={handleSubmits}
              className="bg-[#313A47] py-1 px-3 text-white w-max ml-4 mt-5"
            >
              {loading_ ? "loading..." : "SEND"}
            </button>
          )}
          {/* {excelData && (
            <button
              onClick={uploadProduct}
              className="bg-[#313A47] py-1 px-3 text-white w-max ml-4 mt-5"
            >
              {loading_ ? "loading..." : "SEND"}
            </button>
          )} */}
        </form>
      </div>
    </>
  );
};

export default FormPage;
