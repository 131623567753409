import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login, NotFound, Signup } from "./pages";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Footer, FormSection, Header } from "./components";
import Product from "./pages/product";
import Location from "./pages/location";

function App() {
  return (
    <main className="">
      <ToastContainer className="!w-[250px] !mx-20 md:w-full md:ml-0" />
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/home" element={<Product />} />
          <Route path="/location" element={<Location />} />
          <Route path="/upload" element={<FormSection />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </main>
  );
}

export default App;
