import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "./context";
import { deleteVendorProduct } from "../services/api";
import { toast } from "react-toastify";
import axios from "axios";

const Card = ({ item, setLoading, loading, setInfo }) => {
  const { price, productName, setCategory, imageUrl, description } = item;
  const navigate = useNavigate();
  const { setData } = useStateContext();
  const [id, setID] = useState("");
  const edit = (items) => {
    setData(items);
    navigate(`/upload`);
    setCategory("upload");
  };

  const deleteProduct = (items) => {
  const user = localStorage.getItem("user");
   
    setLoading(true);
    setID(items);
    deleteVendorProduct(items)
      .then((res) => {
        axios
          .get(
            `https://mobileapp.fastcredit-ng.com/api/Product/GetProductByVendorId/${user}`
          )
          .then((res) => {
            setInfo(res.data.responseData);
            setLoading(false);
          })
          .catch((err) => {
            console.log("err", err);
            setLoading(false);
          });
        toast.success(res.data.responseMessage);
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
    // setLoading(false);
  };

  return (
    <div className="m-3 relative bg-white/40 w-[300px] min-h-[250px] p-4 rounded shadow-md flex items-center justify-center flex-col mb-5">
      {/* {icon ?? ''} */}
      <img src={imageUrl} alt="" />
      <p className="capitalize font-medium text-center mb-3  text-green-500">
        product Name <br />
        {productName ?? ""}
      </p>
      <p className="capitalize text-gray-500 font-regular text-center p-5">
        product description <br />
        {description ?? ""}
      </p>
      <p className="capitalize text-gray-500 font-regular text-center p-5">
        product price <br /> &#8358;
        {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? ""}
      </p>
      <div className="flex items-center justify-between w-full">
        <button
          onClick={() => deleteProduct(item.id)}
          className=" text-xl bg-red-500 my-3 py-1 px-6 text-white capitalize"
        >
          {loading && item.id === id ? "deleting..." : "delete"}
        </button>{" "}
        <button
          onClick={() => edit(item)}
          className=" text-xl bg-[#27bec5] py-1 px-6 text-white  capitalize"
        >
          edit
        </button>
      </div>
    </div>
  );
};

export default Card;
