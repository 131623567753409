import React, { useState, useEffect } from "react";
import { CloseIcon, MenuIcon } from "../icons";
import { Link, useNavigate } from "react-router-dom";
import image from "../images/logo2.png";
import { toast } from "react-toastify";

const Header = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const pathname = window.location.pathname;
  const handleShow = () => {
    setShow((prev) => !prev);
  };

  const selectCategory = (item) => {
    // setCategory(item)
    navigate(`/${item}`);
    if (item === "logout") {
      localStorage.removeItem("logged");
      localStorage.removeItem("user");
      navigate("/");
      setShow(true);
      toast.success("Logged Out Successfully");
    }
  };

  useEffect(() => {
    const isLogged = localStorage.getItem("logged");
    console.log("isLogged", isLogged);
    if (isLogged) {
      // navigate('/home')
      return;
    } else {
      window.location.pathname === "/signup"
        ? navigate("/signup")
        : navigate("/");
    }
  }, [navigate]);

  // const NavLinks = [
  //   {
  //     id: 1,
  //     link: '/home',
  //     name: 'Home',
  //   },
  //   {
  //     id: 2,
  //     link: '/about',
  //     name: 'About',
  //   },
  //   {
  //     id: 3,
  //     link: '/contact',
  //     name: 'Contact',
  //   },
  //   {
  //     id: 4,
  //     link: '/service',
  //     name: 'Service',
  //   },
  // ]

  return (
    <main className="sticky top-0 left-0 z-50 bg-[#f5f5f5] shadow-lg h-[100px]">
      <header className="hidden lg:flex items-center justify-between mx-auto max-w-7xl p-5 ">
        <Link to="/home">
          <img src={image} className="-mt-6 h-32 cursor-pointer" alt="logo" />
        </Link>
        {pathname === "/" || pathname === "/signup" ? (
          <div className="-mt-10 flex space-x-6 font-semibold cursor-pointer text-xl text-gray-600 capitalize">
            <Link to="/" className="txl">
              login
            </Link>
            <Link to="/signup">signup</Link>
          </div>
        ) : (
          <div className="flex items-center space-x-7 font-semibold cursor-pointer text-xl text-gray-600 -mt-10 capitalize">
            <p onClick={() => selectCategory("home")}>products</p>
            <p onClick={() => selectCategory("upload")}>upload</p>
            <p onClick={() => selectCategory("location")}>location</p>
            <p onClick={() => selectCategory("logout")}>logout</p>
          </div>
        )}
      </header>
      <header className="flex items-center justify-between mx-auto max-w-7xl p-5 lg:hidden">
        <Link to="/home">
          <img src={image} className="-mt-6 h-32 cursor-pointer" alt="logo" />
        </Link>

        {pathname === "/" || pathname === "/signup" ? (
          <div className="-mt-10 flex space-x-6 font-semibold cursor-pointer text-xl text-gray-600 capitalize">
            <Link to="/" className="txl">
              login
            </Link>
            <Link to="/signup">signup</Link>
          </div>
        ) : show ? (
          <MenuIcon
            onClick={handleShow}
            className="text-gray-500 cursor-pointer -mt-10"
          />
        ) : (
          <CloseIcon
            onClick={handleShow}
            className="text-red-500 cursor-pointer -mt-10"
          />
        )}
        {!show ? (
          <nav className="flex items-center flex-col bg-gray-500  h-[200px] mx-10 w-[200px] p-2 rounded absolute top-20 right-1">
            <div className="flex flex-col justify-center  items-center space-y-4 p-3 font-semibold cursor-pointer text-xl text-white capitalize">
              <p onClick={() => selectCategory("home")}>products</p>
              <p onClick={() => selectCategory("upload")}>upload</p>
              <p onClick={() => selectCategory("location")}>location</p>
              <p onClick={() => selectCategory("logout")}>logout</p>
            </div>{" "}
            {/* { pathname === '/' ||  pathname === '/signup' ?
              <div className='-mt-10 flex space-x-6 font-semibold cursor-pointer text-xl text-gray-600 capitalize'>
                <Link to="/" className='txl'>
                  login
                </Link>
                <Link to="/signup">
                  signup
                </Link>
              </div> :
              <div className='flex flex-col justify-center  items-center space-y-4 p-3 font-semibold cursor-pointer text-xl text-white capitalize'>
                <p onClick={() => selectCategory('home')}>products</p>
                <p onClick={() => selectCategory('upload')}>upload</p>
                <p onClick={() => selectCategory('location')}>location</p>
                <p onClick={() => selectCategory('logout')}>logout</p>
              </div>} */}
          </nav>
        ) : null}
      </header>
    </main>
  );
};

export default Header;
