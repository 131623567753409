import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { CreateLocation, deleteLocation, FetchVendorsLocationById, updateLocation } from '../services/api'

const Location = () => {
  const [info, setInfo] = useState([])
  const [newInfo, setNewInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [view, setView] = useState(0)
  const [inputFile, setInputFile] = useState({
    name: '', address: ''
  })
  const id = localStorage.getItem('user')

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputFile({
      ...inputFile,
      [name]: value,
    });
  }
  const updateView = (num) => {
    setView(num)
  }

  const updateNow = (item) => {
    updateView(2)
    setNewInfo(item)
    setInputFile({
      name: item.name, address: item.address
    })
    console.log('new-item', item)
  }

  const update = (e) => {
    e.preventDefault()
    setLoading2(true)
    const payload = {
      locationId: newInfo?.id,
      name: inputFile.name,
      address: inputFile.address,
    }
    console.log('payload', payload)
    updateLocation(payload)
      .then((res) => {
        console.log('res', res)
        if (res.responseData !== null) {
          toast.success(res.data.responseMessage)
          setLoading2(false)

        }
      })
      .catch((err) => {
        console.log('err', err)
        setLoading2(false)
      })
    setInputFile({
      name: '', address: ''
    })
  }

  const submit = (e) => {
    e.preventDefault()
    setLoading2(true)
    const payload = {
      // vendorId: id,
      vendorId: "4b08ad1a-86b2-478e-8296-1a1c082a4560",
      locations: [
        {
          name: inputFile.name,
          address: inputFile.address,
        },
      ],
    };
    CreateLocation(payload)
      .then((res) => {
        if (res.responseData !== null) {
          toast.success(res.data.responseMessage)
          setLoading2(false)

        }
      })
      .catch((err) => {
        console.log('err', err)
        setLoading2(false)
      })
    setInputFile({
      name: '', address: ''
    })
  }

  useEffect(() => {
    setLoading(true)
    FetchVendorsLocationById(id).then((res) => {
      console.log('res', res.data.responseData)
      setInfo(res.data.responseData)
      setLoading(false)
    })
      .catch((err) => {
        console.log('err', err)
        setLoading(false)
      })
  }, [view,id])


  return (
    <div>

      {loading ? <p className='text-center capitalize text-4xl text-gray-500 font-bold tracking-widest my-10'>
        loading...
      </p> :
        <>
          {view === 0 &&
            <>
              <button onClick={() => updateView(1)} className='btn w-max ml-4 mt-4'>add location</button>
              <h3 className='text-center capitalize text-4xl text-gray-500 font-bold tracking-widest my-10'>  locations available</h3>
              <div className="flex items-center flex-wrap justify-around my-10 mb-10 sm:mt-5">
                {info ?  info.map((item, i) => (
                  <div key={i} className='m-3 w-[300px] h-[200px] font-semibold space-y-4 justify-center flex flex-col items-center shadow rounded-lg bg-slate-100' >
                    <h3>{item.name}</h3>
                    <p>{item.address}</p>
                    <div className='flex items-center space-x-4'>
                      <button onClick={() => deleteLocation(item.id)} className='btn w-max ml-4 mt-4 bg-red-400'>delete </button>
                      <button onClick={() => updateNow(item)} className='btn w-max ml-4 mt-4 bg-gray-400'>edit </button>
                    </div>

                  </div>
                )) : 'no results found'}
              </div>
            </>
          }
          {view === 1 &&
            <>  <button onClick={() => updateView(0)} className='btn w-max ml-4 mt-4 bg-black'>go back</button> <div className='my-10 flex items-center justify-center flex-col'>

              <h3 className='text-center capitalize text-4xl text-gray-500 font-bold tracking-widest my-10'>add location </h3>
              <form onSubmit={submit} className='flex items-center flex-col mx-auto w-1/2 justify-center space-y-10'>
                <input type="text" value={inputFile.name} name='name' placeholder='location' onChange={handleChange} className='input' />
                <input type="text" value={inputFile.address} name='address' placeholder='address' onChange={handleChange} className='input' />
                <button type='submit' className='btn' >{loading2 ? 'submitting...' : 'submit'}</button>
              </form>
            </div> </>}
          {newInfo && view === 2 &&
            <>
            <button onClick={() => updateView(0)} className='btn w-max ml-4 mt-4 bg-black'>go back</button> <div className='my-10 flex items-center justify-center flex-col'>
            <h3 className='text-center capitalize text-4xl text-gray-500 font-bold tracking-widest my-10'>update location </h3>
            <form onSubmit={update} className='flex items-center flex-col mx-auto w-1/2 justify-center space-y-10'>
              <input type="text" value={inputFile.name} name='name' placeholder='location' onChange={handleChange} className='input' />
              <input type="text" value={inputFile.address} name='address' placeholder='address' onChange={handleChange} className='input' />
              <button type='submit' className='btn' >{loading2 ? 'updating...' : 'update'}</button>
            </form>
          </div>
          </>}
        </>}

    </div>
  )
}

export default Location
