import axios from "axios";
import { toast } from "react-toastify";

const Url = "https://mobileapp.fastcredit-ng.com/api";

export const FetchVendors = async () => {
  const url = `${Url}/Vendor/GetAllVendors`;
  try {
    return await axios.get(url);
  } catch (err) {
    return err.response;
  }
};
export const FetchVendorsProduct = async () => {
  const url = `${Url}/Product/GetAllProducts`;
  try {
    return await axios.get(url);
  } catch (err) {
    return err.response;
  }
};

export const FetchVendorsProductById = (id) => {
  // console.log("id", id);
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", "Path=/");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: id,
    redirect: "follow",
  };
  fetch(
    "https://mobileapp.fastcredit-ng.com/api/Product/GetProductById",
    requestOptions
  )
    .then((response) => {
      response.json();
      // console.log("response.json()", response.json());
    })
    .then((result) => console.log("result", result))
    .catch((error) => console.log("error", error));
  //   return await axios.get(url);
  // } catch (err) {
  //   return err.response;
  // }
};
export const FetchVendorsLocation = async () => {
  const url = `${Url}/DeliveryLocation/GetAllDeliveryLocations`;
  try {
    return await axios.get(url);
  } catch (err) {
    return err.response;
  }
};

export const FetchVendorsLocationById = async (id) => {
  const url = `${Url}/DeliveryLocation/GetAllDeliveryLocations/${id}`;
  try {
    return await axios.get(url);
  } catch (err) {
    return err.response;
  }
};
// signup
export const CreateVendor = async (payload) => {
  // const url = ;
  try {
    return await axios
      .post(`${Url}/Vendor/CreateVendor`, payload)
      .then((res) => res);
  } catch (err) {
    return err.response;
  }
};

// signin
export const signIn = async (payload) => {
  const url = `${Url}/Vendor/ValidateVendor`;
  try {
    return await axios.post(url, payload).then((res) => res);
  } catch (err) {
    return err.response;
  }
};
const fcloptions = {
  headers: {
    // Authorization: `Bearer ${data}`,
    "Access-Control-Allow-Origin": "*",
    "X-API-KEY": "FCL-LOAN-REQUEST",
  },
};
export const CreateProduct = async (payload) => {
  const newPayload = [payload];
  // console.log("selectedPayload", payload);
  const url = `${Url}/Product/CreateProduct?vendorId=${payload.vendor}`;

  try {
    return await axios.post(url, newPayload, fcloptions).then((res) => {
      toast.success(res.data.responseMessage);
    });
  } catch (err) {
    return err.response;
  }
};
export const UpdateProduct = async (payload) => {
  const newPayload = [payload];
  console.log("newPayload", newPayload);
  const url = `${Url}/Product/UpdateProduct`;

  try {
    return await axios.post(url, newPayload, fcloptions).then((res) => {
      toast.success(res.data.responseMessage);
    });
  } catch (err) {
    return err.response;
  }
};

export const CreateLocation = async (payload) => {
  const url = `${Url}/DeliveryLocation/CreateDeliveryLocation`;
  try {
    return await axios.post(url, payload).then((res) => res);
  } catch (err) {
    return err.response;
  }
};
export const updateLocation = async (payload) => {
  const newPayload = [payload];

  const url = `${Url}/DeliveryLocation/UpdateLocation`;
  try {
    return await axios.post(url, newPayload).then((res) => res);
  } catch (err) {
    return err.response;
  }
};
export const deleteLocation = async (locationId) => {
  const url = `${Url}/DeliveryLocation/DeleteLocation/${locationId}`;
  try {
    return await axios.delete(url).then((res) => res);
  } catch (err) {
    return err.response;
  }
};
export const deleteVendor = async (locationId) => {
  const url = `${Url}/Vendor/DeleteVendor/${locationId}`;
  try {
    return await axios.delete(url).then((res) => res);
  } catch (err) {
    return err.response;
  }
};
export const deleteVendorProduct = async (id) => {
  const url = `${Url}/Product/DeleteProduct/${id}`;
  try {
    return await axios.delete(url, fcloptions).then((res) => res);
  } catch (err) {
    return err.response;
  }
};
