import React, { useState } from "react";
import { toast } from "react-toastify";
import { CreateVendor } from "../services/api";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const [loading, setLoading] = useState(false);
  const [inputFile, setInputFile] = useState({
    name: "",
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputFile({
      ...inputFile,
      [name]: value,
    });
  };

  const signup = (e) => {
    setLoading(true);
    e.preventDefault();
    if (
      inputFile.email === "" ||
      inputFile.password === "" ||
      inputFile.name === ""
    ) {
      toast.error("fill all fields");
        setLoading(false);

      return
    }
    const payload = {
      vendorName: inputFile.name,
      email: inputFile.email,
      password: inputFile.password,
    };
    CreateVendor(payload)
      .then((res) => {
        if (res.responseData !== null) {
          toast.success(res.data.responseMessage);
          navigate("/home");
          setLoading(false);
          localStorage.setItem("logged", true);
          localStorage.setItem("user", res.data?.responseData.id);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
    setInputFile({
      email: "",
      password: "",
      name: "",
    });
        setLoading(false);

  };
  return (
    <div className="my-10">
      <h3 className="text-center capitalize text-4xl text-gray-500 font-bold tracking-widest my-10">
        signup page
      </h3>

      <form
        onSubmit={signup}
        className="flex items-center flex-col mx-auto w-1/2 justify-center space-y-10"
      >
        <input
          type="text"
          value={inputFile.name}
          name="name"
          placeholder="vendor name"
          onChange={handleChange}
          className="input"
        />
        <input
          type="email"
          value={inputFile.email}
          name="email"
          placeholder="vendor email"
          onChange={handleChange}
          className="input"
        />
        <input
          type="password"
          value={inputFile.password}
          name="password"
          placeholder="password"
          onChange={handleChange}
          className="input"
        />
        <button type="submit" className="btn">
          {loading ? "submitting..." : "submit"}
        </button>
      </form>
    </div>
  );
};

export default Signup;
