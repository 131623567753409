import axios from "axios";
import React, { useDeferredValue, useEffect, useMemo, useState } from "react";
import Card from "../components/Card";
import { CreateProduct, deleteVendor } from "../services/api";

const Product = () => {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const id = localStorage.getItem("user");
  const [searchItem, setSearchItem] = useState("");
  const deferedValue = useDeferredValue(searchItem);

  const handleSearch = useMemo(
    () =>
      deferedValue
        ? info?.filter(
            (item) =>
              item?.productName
                ?.toLowerCase()
                ?.includes(deferedValue.toLowerCase().trim()) ||
              item?.productCode
                ?.toLowerCase()
                ?.includes(deferedValue.toLowerCase().trim()) ||
              item?.productType
                ?.toLowerCase()
                ?.includes(deferedValue.toLowerCase().trim()) ||
              item?.description
                ?.toLowerCase()
                ?.includes(deferedValue.toLowerCase().trim())
          )
        : info,
    [deferedValue, info]
  );
  useEffect(() => {
    window.scrollTo(0,0)
    // deleteVendor(id)
    //   .then((res) => console.log("res", res))
    //   .catch((err) => {
    //     console.log("err", err);
    //     // setLoading(false);
    //   });
    setLoading(true);
    axios
      .get(
        `https://mobileapp.fastcredit-ng.com/api/Product/GetProductByVendorId/${id}`
      )
      .then((res) => {
        setInfo(res.data.responseData);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  }, [id]);

  return (
    <div>
      {loading ? (
        <p className="text-center capitalize text-4xl text-gray-500 font-bold tracking-widest my-10">
          loading...
        </p>
      ) : (
        <>
          <h3 className="text-center capitalize text-4xl text-gray-500 font-bold tracking-widest my-10">
            {" "}
            all products
          </h3>
          <div className="w-1/2  flex items-center justify-center mx-auto">
            <input
              type="text"
              placeholder="search products"
              className="p-3 rounded w-full bg-gray-200"
              name=""
              id=""
              onChange={(e) => setSearchItem(e.target.value)}
            />
          </div>

          <div className="flex items-center flex-wrap justify-around my-10 mb-10 sm:mt-5">
            {handleSearch.length > 0
              ? handleSearch.map((item, i) => (
                  <Card
                    setLoading={setLoading2}
                    loading={loading2}
                    item={item}
                    setInfo={setInfo}
                    key={i}
                  />
                ))
              : "no result found"}
          </div>
        </>
      )}
    </div>
  );
};

export default Product;
