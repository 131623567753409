import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signIn } from "../services/api";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inputFile, setInputFile] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputFile({
      ...inputFile,
      [name]: value,
    });
  };

  const login = (e) => {
    setLoading(true);
    e.preventDefault();
    if (inputFile.email === "" || inputFile.password === "") {
      toast.error("fill all fields");
      setLoading(false);
      return;
    } else {
      const payload = {
        email: inputFile.email,
        password: inputFile.password,
      };
      signIn(payload)
        .then((res) => {
          toast.success(res.data.responseMessage);
          if (res.data.responseCode !== 400) {
            navigate("/home");
            localStorage.setItem("user", res.data?.responseData.vendorId);
            localStorage.setItem("logged", true);
          } else {
            navigate("/");
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
      setInputFile({
        email: "",
        password: "",
      });
    }
  };
  return (
    <div className="my-10">
      <h3 className="text-center capitalize text-4xl text-gray-500 font-bold tracking-widest my-10">
        login page
      </h3>

      <form
        onSubmit={login}
        className="flex items-center flex-col mx-auto w-1/2 justify-center space-y-10"
      >
        <input
          type="email"
          value={inputFile.email}
          name="email"
          placeholder="vendor email"
          onChange={handleChange}
          className="input"
        />
        <input
          type="password"
          value={inputFile.password}
          name="password"
          placeholder="password"
          onChange={handleChange}
          className="input"
        />
        <button type="submit" className="btn">
          {loading ? "submitting..." : "submit"}
        </button>
      </form>
    </div>
  );
};

export default Login;
