import React from 'react'
import { footerContent } from './data'
import image from '../images/logo2.png'

const Footer = () => {
  return (
    <footer className='flex flex-col-reverse bg-gray-100 shadow-lg xl:flex-row xl:items-start sm:p-10 sm:pl-20 xl:pl-32'>
      <div className='grid flex-row grid-cols-2 px-10 space-x-1 lg:flex lg:py-10'>
        {footerContent.map((item, index) => (
          <div key={index} className='cursor-pointer'>
            <h3 className='text-xl font-semibold capitalize'>{item.title}</h3>
            <aside className='flex-row justify-between  lg:flex'>
              <div>
                {item.content.map((link, index) => (
                  <p key={index} className='mr-10 w-[100px] text-xs sm:text-sm'>{link}</p>
                //  <Link to={`/${link.toLowerCase().replace(/\s/g, '')}`} >
                //     </Link>
                ))}
              </div>
            </aside>
          </div>
        ))}
      </div>
      <section className='ml-3 my-4 xl:-mt-[1em] sm:-ml-1 xl:-ml-10'>
        <img src={image} className='w-1/2 sm:h-[200px] sm:w-[300px] object-cover' alt="logo" />
        <div className='-mt-10 p-6 text-xs sm:text-sm sm:ml-10 sm:w-[250px] md:p-0'>
          <h3 className='mb-2'><span className='font-semibold capitalize'>Address: </span> Ground Floor, Wing C, Motorways Complex, Opposite 7-Up Alausa, Lagos Ikeja, 010010, Ikeja</h3>
          <p><span className='font-semibold capitalize'>Phone: </span> +234-1-888 5046</p>
        </div>
      </section>
    </footer>
  )
}
export default Footer