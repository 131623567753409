import React from 'react'

const FormInputs = ({ inputFile, handleInputChange, handleFileInput }) => {
  return (
    <>
      <label className="block mt-2">
        <span className="block font-bold text-slate-700 text-1xl">
          Product name
        </span>
        <input
          type="text"
          value={inputFile.productName}
          onChange={handleInputChange}
          name="productName"
          className="w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm text-1xl boreder-slate-300 focus:outline-none focus:border-sky-500 focus:ring-1 invalid:red-600 invalid:border-4"
          placeholder="Product name"
        />
      </label>
      <label className="block mt-2">
        <span className="block font-bold text-slate-700 text-1xl">
          Product code
        </span>
        <input
          type="text"
          value={inputFile.code}
          placeholder="Product code"
          onChange={handleInputChange}
          name="code"
          className="w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm text-1xl boreder-slate-300 focus:outline-none focus:border-sky-500 focus:ring-1 invalid:red-600 invalid:border-4"
        />
      </label>
      <label className="block mt-2">
        <span className="block font-bold text-slate-700 text-1xl">
          Product discount
        </span>
        <input
          type="number"
          onChange={handleInputChange}
          value={inputFile.discount}
          name="discount"
          className="w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm text-1xl boreder-slate-300 focus:outline-none focus:border-sky-500 focus:ring-1 invalid:red-600 invalid:border-4"
          placeholder="Product discount"
        />
      </label>
      <div className="flex justify-between w-full">
        <label className="block w-full mt-2 mr-2">
          <span className="block font-bold text-slate-700 text-1xl">
            category
          </span>
          {/* <input
                type="text"
                value={inputFile.category}

                onChange={handleInputChange}
                name='category'
                className="w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm text-1xl boreder-slate-300 focus:outline-none focus:border-sky-500 focus:ring-1 invalid:red-600 invalid:border-4"
                placeholder=""
              /> */}
          <select
            onChange={handleInputChange}
            name="category"
            className="w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm text-1xl boreder-slate-300 focus:outline-none focus:border-sky-500 focus:ring-1 invalid:red-600 invalid:border-4"
          >
            <option value="">choose category</option>
            <option value="electronics">Electronics</option>
            <option value="furniture">Furniture</option>
            <option value="Mobile Phones and Tablets">
              Mobile Phones and Tablets
            </option>
            <option value="home-appliance">Home Appliance</option>
          </select>
        </label>
        <label className="block w-full mt-2">
          <span className="block font-bold text-slate-700 text-1xl">
            sub category
          </span>
          {/* <input
                type="text"
                value={inputFile.subcategory}
                onChange={handleInputChange}
                name="subcategory"
                placeholder="sub category "
                className="w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm text-1xl boreder-slate-300 focus:outline-none focus:border-sky-500 focus:ring-1 invalid:red-600 invalid:border-4"
              /> */}
          <select
            onChange={handleInputChange}
            name="subcategory"
            className="capitalize  w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm text-1xl boreder-slate-300 focus:outline-none focus:border-sky-500 focus:ring-1 invalid:red-600 invalid:border-4"
          >
            <option value="">choose subcategory</option>
            {inputFile.category === "electronics" && (
              <>
                <option value="smart phone">smart phone</option>
                <option value="fridge">fridge</option>
                <option value="television">television</option>
                <option value="laptop">laptop</option>
              </>
            )}
            {inputFile.category === "furniture" && (
              <>
                <option value="sofa">sofa</option>
                <option value="chair">chair</option>
                <option value="table">table</option>
              </>
            )}
            {inputFile.category === "home-appliance" && (
              <>
                <option value="iron ">iron </option>
                <option value="air condition">air condition</option>
                <option value="sound system">sound system</option>
              </>
            )}
            {inputFile.category === "Mobile Phones and Tablets" && (
              <>
                <option value="Mobile Phones ">Mobile Phones </option>
                <option value="Tablets">Tablets</option>
                <option value="Smart watches and Trackers">
                  Smart watches and Trackers
                </option>
                <option value="Accessories for Mobile Phone and Tablets">
                  Accessories for Mobile Phone and Tablets
                </option>
              </>
            )}
          </select>
        </label>
      </div>
      <label className="block mt-2">
        <span className="block font-bold text-slate-700 text-1xl">
          Upload image
        </span>
        <input
          type="file"
          placeholder="Upload product image"
          onChange={handleFileInput}
          name="image"
          accept="image/*"
          className="w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm text-1xl boreder-slate-300 focus:outline-none focus:border-sky-500 focus:ring-1 invalid:red-600 invalid:border-4"
        />
      </label>
      <label className="block mt-2">
        <span className="block font-bold text-slate-700 text-1xl">Price</span>
        <input
          type="number"
          placeholder="Price"
          value={inputFile.price}
          onChange={handleInputChange}
          name="price"
          className="w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm text-1xl boreder-slate-300 focus:outline-none focus:border-sky-500 focus:ring-1 invalid:red-600 invalid:border-4"
        />
      </label>
      <label className="block mt-2">
        <span className="block font-bold text-slate-700 text-1xl">
          Product description
        </span>
        <textarea
          type="text"
          value={inputFile.desc}
          onChange={handleInputChange}
          name="desc"
          className="resize-none w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm text-1xl boreder-slate-300 focus:outline-none focus:border-sky-500 focus:ring-1 invalid:red-600 invalid:border-4"
          placeholder="Product description"
        />
      </label>
    </>
  );
};

export default FormInputs