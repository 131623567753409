export const footerContent = [
    {
      id: 1,
      title: "Features",
      content: [
        "Fast account",
        "Payments",
        "Cards",
        "Investments",
        "Credit report",
        "Loans",
      ],
    },
    {
      id: 2,
      title: "Learn more",
      content: [
        "Personal loans",
        " Get loans fast",
        " Loans in Nigeria",
        " Fast loan",
        "SME loans",
        "Instant loans",
        "Fast Credit app ",
        "Loans for business ",
        "Loans without collateral ",
      ],
    },
    {
      id: 3,
      title: "Company",
      content: ["About us", "Media", "Careers", "Blog", "Customers"],
    },
    {
      id: 4,
      title: "Help",
      content: [" Knowledge base", "Contact", "Community", "FAQ"],
    },
    {
      id: 5,
      title: "Legal",
      content: ["Privacy policy", "Terms & Conditions", "Cookies"],
    },
  ];